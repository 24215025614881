<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData" class="mb-20">

			<a-row :gutter="24" class="px-10" style="margin-top: 0px;">
				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="">
					<a-card id="zeroPaddingCard" class="p-0 m-0 pb-0">
                        <a-row :gutter="24" class="pt-0">
                            <a-col :span="24" :md="10">
                                <div class="icon">
                                    <img id="classImg" width="100%" height="320px" style="border-radius: 10px 0px 0px 10px;" :src="classDetails.thumbnail != null ? classDetails.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                </div>
                            </a-col>
                            <a-col :span="24" :md="14" class="px-20">
                                <a-row :gutter="24">
                                    <a-col :span="24">
                                        <h4 class="mt-10 pt-10 pl-2">{{ classDetails.name }}</h4>
                                        <p>{{ classDetails.objective }}</p>
                                    </a-col>
                                </a-row>
                                <a-row :gutter="24">
                                    <a-col :span="24" :sm="24" :md="24">
                                        Taught by : &nbsp;&nbsp;<a-avatar :size="30" :src="classDetails.creator != null && classDetails.creator.profile ? classDetails.creator.profile : ``" />&nbsp;&nbsp;
                                    
                                        <span style="text-decoration: underline;" @click="toTutorProfilePage(classDetails.creator.uuid)">
                                            {{ classDetails.creator != null ? `${classDetails.creator.firstName} ${classDetails.creator.lastName}` : 'Unknown user'}}
                                        </span>
                                        &nbsp;&nbsp;
                                        <svg class="dark:text-white" style="color: #F9AA34; width: 15px; position: absolute; top: 5px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;
                                        {{ classDetails.rating }}   
                                    </a-col>
                                </a-row>

                                <a-row :gutter="24" class="" style="margin-top: auto; padding-top: 30px;">
                                    <a-col :span="24" :md="12" :lg="12" :xl="12" class="mt-20">
                                        <a-button type="primary" block :loading="joinClass.loading" @click="handleJoinClass">Join Class for {{ classDetails.price != 0 && classDetails.price != null ? `${classDetails.price}/=` : 'FREE' }}</a-button>
                                    </a-col>
                                    <a-col :span="12" :md="12" :lg="12" :xl="7" class="mt-20">
                                        <a-button id="wishBtn" :loading="favoriteLoading" type="#F1EFFA" block @click="handleAddFavorite">❤️ &nbsp;Add to Wish-list</a-button>
                                    </a-col>
                                    <a-col :span="12" :md="12" :lg="12" :xl="5" class="mt-20 mb-20">
                                        <a-button id="shareBtn" type="#F1EFFA" block @click="copyUrl"><font-awesome-icon icon="fa-brands fa-whatsapp fa-xl" /> Share Class</a-button>
                                    </a-col>
                                </a-row>
                                
                            </a-col>
                        </a-row>
                    </a-card>
				</a-col>
            </a-row>

            <a-row :gutter="24" class="px-10 mt-20" style="">
                <a-col :span="24" :md="10" class="mb-24 pb-10">
                    <a-card class="p-0 m-0 pb-0">
                        <a-row :gutter="24">
                            <a-col :span="24">
                                <h5>This class includes:</h5>
                            </a-col>
                        </a-row>

                        <a-row :gutter="24">
                            <a-col :span="24">
                                <p>📖 &nbsp; {{ classDetails.lessonNum }} Lessons</p>
                            </a-col>

                            <!-- <a-col :span="24">
                                <p>📚 &nbsp; 40 Downloadable Resources</p>
                            </a-col> -->

                            <a-col :span="24">
                                <p>🧩 &nbsp; {{ classDetails.quizNum }} Quizzes</p>
                            </a-col>

                            <a-col :span="24">
                                <p>🏆 &nbsp; Certificate of Completion</p>
                            </a-col>

                            <a-col :span="24">
                                <p>♾️ &nbsp; Lifetime Access</p>
                            </a-col>

                            <a-col :span="24">
                                <p>👣 &nbsp; Flexible Schedule</p>
                            </a-col>

                            <a-col :span="24" class="py-10">
                                <hr style="border: 0.8px solid #ECEBEB;"/>
                            </a-col>

                            <!-- <a-col :span="24">
                                <p>🗓 &nbsp; Begins 2nd of Feb to 16 Feb</p>
                            </a-col> -->

                            <a-col :span="24">
                                <p>👤 &nbsp; {{ classDetails.numOfSlots - classDetails.members.length }} Slots remaining</p>
                            </a-col>

                            <a-col :span="24" class="pt-10 pb-20">
                                <a-button id="joinBtn" type="primary" block :loading="joinClass.loading" @click="handleJoinClass" style="">
                                    Join Class for {{ classDetails.price != 0 && classDetails.price != null ? `${classDetails.price}/=` : 'FREE' }}
                                </a-button>
                            </a-col>
                            
                        </a-row>

                    </a-card>
                </a-col>

                <a-col :span="24" :md="14" class="mb-24 pb-10 ml-0 pl-0">
                    <a-card class="p-0 m-0 pb-20 ml-0 pl-0">

                        <a-row :gutter="24" v-if="classDetails.specificObjectives != null && classDetails.specificObjectives.length > 0">
                            <a-col :span="24">
                                <h5>In this class you’ll learn:</h5>
                            </a-col>
                        </a-row>

                        <a-row :gutter="24" class="mt-5 mb-20" v-if="classDetails.specificObjectives != null && classDetails.specificObjectives.length > 0">
                            <a-col :span="24" :md="12" v-for="(obj, index) in classDetails.specificObjectives" :key="index" class="mt-10">
                                <a-row :gutter="24">
                                    <a-col :span="2">
                                        <p><font-awesome-icon icon="fa-solid fa-check fa-xl" style="color: #2AA81A;"/></p>
                                    </a-col>
                                    <a-col :span="20">
                                        <p>{{ obj }}</p>
                                    </a-col>
                                </a-row>
                            </a-col>
                        </a-row>


                        <a-row :gutter="24" class="" v-if="topics != null && topics.length > 0">
                            <a-col :span="24">
                                <h5>Class Content</h5>
                            </a-col>
                        </a-row>

                        <a-row :gutter="24" class="mt-5 mb-20 px-0 py-10" style="background-color: #F7F7F7;" v-if="topics != null && topics.length > 0">
                            <a-col :span="24" :md="24" v-for="topic in topics" :key="topic.uuid" class="mt-10">
                                <a-row :gutter="24">
                                    <a-col :span="24">
                                        <p class="pb-0 mb-0">Topic {{ topic.position }}</p>
                                        <h6 style="font-size: 16px; font-weight: 500;">{{ topic.title }}</h6>
                                    </a-col>
                                </a-row>
                            </a-col>
                        </a-row>

                        

                        <a-row :gutter="24" class="" v-if="classDetails.features != null && classDetails.features.length > 0">
                            <a-col :span="24">
                                <h5>Class Features</h5>
                            </a-col>
                        </a-row>

                        <a-row :gutter="24" class="" v-if="classDetails.features != null && classDetails.features.length > 0">
                            <a-col :span="12" :sm="12" :md="12" :lg="8" :xl="6" v-for="(option, index) in classDetails.features" class="mt-20 text-center" :key="index">
                                <label style="font-weight: 600">{{ formatFetures(option) }}</label><br/>
                                <a-avatar :size="70" class="mt-10" style="background: #CCCCCC"><span style="font-size: 35px">{{ getIconFeature(option) }}</span></a-avatar>
                            </a-col>
                        </a-row>

                    </a-card>
                </a-col>
            </a-row>

<!-- 
            <a-row :gutter="24">
                                    <a-col :span="24" class="mt-0">
                                        <label style="font-size: 16px;" class="mt-10" v-if="classDetails.objective"><strong>Objective</strong></label>
                                        
                                    </a-col>
                                    <a-col :span="24">
                                            <svg class="text-primary dark:text-white"  style="width: 20px; position: absolute; top: 0px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H5a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Zm3-7h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Zm-8 4h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Z"/>
                                            </svg>
                                            <span class="pb-15 pl-20 ml-5">
                                            Begins from <strong>{{ $Moment(classDetails.startTimestamp).format("DD MMM YYYY") }}</strong> to <strong>{{ $Moment(classDetails.startTimestamp).format("DD MMM YYYY") }}</strong>
                                        </span>
                                    </a-col>
                                    <a-col :span="24" class="mt-10">
                                            <svg class="text-primary dark:text-white" style="width: 20px; position: absolute; top: 0px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd" d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z" clip-rule="evenodd"/>
                                            </svg>
                                            <span class="pb-15 pl-20 ml-5">
                                            <strong>{{ classDetails.numOfSlots }}</strong> Slots remaining
                                        </span>
                                    </a-col>
                                    <a-col :span="24" class="mt-20">
                                        <a-button type="primary" :loading="joinClass.loading" @click="handleJoinClass">Join Class for {{ classDetails.price != 0 && classDetails.price != null ? `${classDetails.price}/=` : 'FREE' }}</a-button>
                                    </a-col>

                                </a-row> -->
                                
		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>
	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;
    import { notification } from 'ant-design-vue';

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,
                favoriteLoading: false,
                activeTab: 1,
				classes: [],
                classDetails: {
                    uuid: null,
                    name: null,
                    objective: null,
                    specificObjectives: [],
                    isPublic: null,
                    educationSystems: [],
                    educationLevels: [],
                    educationGrades: [],
                    subjects: [],
                    members: [],
                    numOfSlots: null,
                    rating: null,
                    creator: {},
                    setupCompleted: null,
                    isAcademic: null,
                    price: 0,
                    lessonNum: 0,
                    quizNum: 0,
                    isForTeaching: null,
                    features: [],
                    assessmentModes: [],
                    startTimestamp: null,
                    endTimestamp: null,
                    thumbnail: null,
                    createdAt: null,
                    updatedAt: null,
                },

                joinClass: {
                    loading: false,
                },

                topics: [],

                userDetails: {},

				currentPage: 1,

      		}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Classes', url: '/classes', isActive: false },
                { title: 'Details', url: '', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.getUserDetails();
			this.getClassDetails();
            this.getClassTopics();
            this.getClassQuizzes();
		},
		methods: {

            copyUrl() {
                let link = `${this.$FRONTEND_URL}${this.$route.path}`;
                
                navigator.clipboard.writeText(link);

                this.notify('Link has been copied successfully', 'success')
            },

            formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

            formatFetures(name) {
				let formattedName = name.replace(/_/g, ' ');
                let formattedName1 = formattedName.includes('mode') ? formattedName.substring(5) : formattedName;
                return formattedName1[0].toUpperCase() + formattedName1.substring(1);
            },

            getIconFeature(name) {
                let icon = ''
				let formattedName = name.replace(/_/g, ' ');
                let formattedName1 = formattedName.includes('mode') ? formattedName.substring(5) : formattedName;
                let finalName = formattedName1[0].toUpperCase() + formattedName1.substring(1);

                if(finalName == 'Live class') {
                    icon = '📹';
                }
                else if(finalName == 'Notes') {
                    icon = '📖';
                }
                else if(finalName == 'Session recording') {
                    icon = '💾';
                }
                else if(finalName == 'Live chat') {
                    icon = '💬';
                }
                else if(finalName == 'Quiz') {
                    icon = '🏠';
                }
                else if(finalName == 'Past papers') {
                    icon = '📝';
                }

                return icon;
            },

            toTutorProfilePage(uuid) {
                this.$router.push(`/tutors/view/${uuid}`)
            },

            async getUserDetails() {

				let userInfo = await localStorage.getItem("user_details")

				if(userInfo != null) {
                    this.userDetails = JSON.parse(userInfo);
				}
			},

			async getClassDetails() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/classes/?uuid=${this.$route.params.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classDetails.uuid = response.data.data.uuid;
                        this.classDetails.name = response.data.data.name;
                        this.classDetails.objective = response.data.data.objective;
                        this.classDetails.specificObjectives = response.data.data.specificObjectives;
                        this.classDetails.isPublic = response.data.data.isPublic;
                        this.classDetails.educationSystems = response.data.data.educationSystems;
                        this.classDetails.educationLevels = response.data.data.educationLevels;
                        this.classDetails.educationGrades = response.data.data.educationGrades;
                        this.classDetails.subjects = response.data.data.subjects;
                        this.classDetails.numOfSlots = response.data.data.numOfSlots;
                        this.classDetails.rating = response.data.data.rating;
                        this.classDetails.creator = response.data.data.creator;
                        this.classDetails.setupCompleted = response.data.data.setupCompleted;
                        this.classDetails.isAcademic = response.data.data.isAcademic;
                        this.classDetails.price = response.data.data.price;
                        this.classDetails.isForTeaching = response.data.data.isForTeaching;
                        this.classDetails.features = response.data.data.features;
                        this.classDetails.assessmentModes = response.data.data.assessmentModes;
                        this.classDetails.startTimestamp = response.data.data.startTimestamp;
                        this.classDetails.endTimestamp = response.data.data.endTimestamp;
                        this.classDetails.thumbnail = response.data.data.thumbnail;
                        this.classDetails.createdAt = response.data.data.createdAt;
                        this.classDetails.updatedAt = response.data.data.updatedAt;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getClassTopics() {

				this.loadingData = true;

                const currentPage = 1; 
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/topics?clazz=${this.$route.params.uuid}&isSubtopic=true&orderBy=position&order=ASC&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.topics = response.data.data;
                        this.classDetails.lessonNum = response.data.data.length
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getClassQuizzes() {

                this.loadingData = true;

				const currentPage = 1 
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/quizzes?clazz=${this.$route.params.uuid}&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classDetails.quizNum = response.data.data.length;
                    }

                    this.loadingData = false;
					
                }).catch(async(error) => {

                    this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async handleJoinClass() {

                if(this.classDetails.price > 0) {

                    this.joinClass.loading = true;

                    let paymentDetails = {
                        type: "class",
                        amount: this.classDetails.price,
                        isClazz: true,
                        isQuiz: false,
                        isMaterials: false,
                        isBooking: false,
                        item: this.classDetails.uuid,
                        paidBy: this.userDetails.uuid,
                        paidTo: this.classDetails.creator.uuid,
                        redirectTo: `${this.$FRONTEND_URL}/payments/${this.classDetails.uuid}`,
                    }

                    let url = `${this.$BACKEND_URL}/payments/link`;

                    this.$AXIOS.post(url, paymentDetails).then(async(response) => {
                        if (response.status >= 200 && response.status < 210) {

                            await localStorage.setItem('transaction_details', JSON.stringify(response.data.data));

                            this.joinClass.loading = false;

                            window.open(response.data.data.paymentLink, '_blank');
                            
                        }

                    }).catch((err) => {
                        
                        this.joinClass.loading = true;
                        
                        this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');

                    });
                    
                    
                }else {

                    const userInfo = await localStorage.getItem('user_details')

					let userDetails = JSON.parse(userInfo);

				    this.joinClass.loading = true;

				    let url = `${this.$BACKEND_URL}/members/${this.$route.params.uuid}`;

                    this.$AXIOS.post(url, {uuid: userDetails.uuid}).then(async(response) => {

                        this.notify('You have successfully joined the class', 'success')

                        this.joinClass.loading = false;

                        this.$router.push(`/my-classes/view/${this.$route.params.uuid}`)

                    }).catch(async(error) => {
                        
                        this.joinClass.loading = false;

                        this.newClass.errorMessage = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
                        this.newClass.errorShow = true

                        if(error.response && error.response.status == 401) {
                            await localStorage.setItem("user_token", null);
                            await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                            this.$router.push(`/sign-in`);
                        }
                    });
                }

            },


            async handleAddFavorite() {

                this.favoriteLoading = true;

                let payload = {
                    uuid: this.userDetails.uuid,
                }

                let url = `${this.$BACKEND_URL}/classes/favorites/${this.classDetails.uuid}`;

                this.$AXIOS.post(url, payload).then(async(response) => {
                    if (response.status >= 200 && response.status < 210) {

                        this.notify("Class has been added to my favorites list", 'success');

                        this.favoriteLoading = false;
                        
                    }

                }).catch((err) => {
                    
                    this.favoriteLoading = true;
                    
                    this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');

                });

            },

            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}

#shareBtn, #shareBtn:hover {
    background: #2AA81A;
    color: #FFFFFF !important;
    border: none !important;
}

#wishBtn, #wishBtn:hover {
    background: #F1EFFA;
    color: #54398D !important;
    border: none !important;
}

#joinBtn, #joinBtn:hover {
    background: #F3F2F4 !important;
    color: #54398D !important;
    border: none !important;
}
</style>
